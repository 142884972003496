import request from '@/utils/request'


// 查询商品足迹列表
export function listBrowse(query) {
  return request({
    url: '/goods/hot/list',
    method: 'get',
    params: query
  })
}

// 查询商品足迹分页
export function pageBrowse(query) {
  return request({
    url: '/goods/hot/page',
    method: 'get',
    params: query
  })
}

// 查询商品足迹详细
export function getBrowse(data) {
  return request({
    url: '/goods/hot/detail',
    method: 'get',
    params: data
  })
}

// 新增商品足迹
export function addBrowse(data) {
  return request({
    url: '/goods/hot/add',
    method: 'post',
    data: data
  })
}

export function updateSort(data) {
  return request({
    url: '/goods/hot/sort',
    method: 'post',
    data: data
  })
}

// 修改商品足迹
export function updateBrowse(data) {
  return request({
    url: '/goods/hot/edit',
    method: 'post',
    data: data
  })
}

// 删除商品足迹
export function delBrowse(data) {
  return request({
    url: '/goods/hot/delete',
    method: 'post',
    data: data
  })
}
